import Swiper from 'swiper'
import { FreeMode } from 'swiper/modules'

class AppBlock extends HTMLElement {
  constructor() {
    super()

    this.instaFeed = this.querySelector('#insta-feed')

    if (this.instaFeed) {
      this.initSwiper()

      window.addEventListener('resize', debounce(() => {this.initSwiper()}, 300))
    }
  }

  async findElementsAsync(root = document, selector, interval = 500, maxAttempts = 10) {
    let i
    let attempts = 0
    return new Promise((resolve, reject) => {
      i = setInterval(() => {
        attempts++
        const el = root.querySelectorAll(selector)
        if (el.length) {
          clearInterval(i)
          resolve(el)
        }
        if (attempts >= maxAttempts) {
          clearInterval(i)
          reject(new Error('Element not found!'))
        }
      }, interval)
    })
  }

  async initSwiper() {
    const carousel = await this.findElementsAsync(this.instaFeed, '.instafeed-new-layout-container')

    if (!carousel[0]) return

    const carouselWrapper = carousel[0].querySelector('.instafeed-new-layout-wrapper')
    const carouselSlides = carousel[0].querySelectorAll('.instafeed-new-layout-item')

    carousel[0].classList.add('swiper')
    carouselWrapper.classList.add('swiper-wrapper')
    carouselSlides.forEach(slide => slide.classList.add('swiper-slide'))

    const swiper = new Swiper(carousel[0], {
      modules: [FreeMode],
      slidesPerView: 'auto',
      spaceBetween: 12,
      initialSlide: 1,
      loop: true,
      breakpoints: {
        768: {
          slidesPerView: 4,
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: 4,
          initialSlide: 0
        },
        1280: {
          slidesPerView: 6,
          spaceBetween: 16
        }
      }
    })
  }
}

window.customElements.define('app-block', AppBlock)